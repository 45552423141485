import './App.css';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, useParams } from 'react-router-dom';

import TopNav from './Component/TopNav';
import Landing from './Page/Landing';
import About from './Page/About';
import Towns from './Page/Towns';
import Error from './Page/Error';
import BottomNav from './Component/BottomNav';
import './fonts/ShadowsIntoLight-Regular.ttf';

function App() {
  return (
   <BrowserRouter>
    <div>
          <TopNav/>
            <Routes>
               <Route path="/" element={<Landing/>} />
               <Route path="/About" element={<About/>} />
               <Route path="/:town" element={<Towns/>} />
               <Route path="/*" element={<Error/>} />
            </Routes>

          <BottomNav/>

    </div>
  </BrowserRouter>
  );
}

export default App;
