import React, {useEffect} from "react";
import Error from "./Error";
import '../css/town.css';
import Hero from "../Component/Hero";
import TownBeachMaps from "../Component/TownBeachMaps";
import { useParams } from 'react-router-dom';
import {capeTowns} from '../data/towns';

const Towns= () => {
    let { town  } = useParams();

    const details = capeTowns.find(element => element.name === town);
    if(!details){
       return <Error/>; 
    }

    var icon = `/town-seal/micro/${details.icon}`;

    return (
    <>
    <div className="town-page-background-image">
        <div className="town-page-row">
            <div className="town-padding-color">  
                <div className="town-box "> 
                    <div className="town-top-column">

                        <h1>Welcome To The Town Of {details.name}</h1> 
                        <img src={icon}/>

                    <p>
                        A super long paragraph of info.
                        A super long paragraph of info.
                        A super long paragraph of info.
                        A super long paragraph of info.
                        A super long paragraph of info.
                        A super long paragraph of info.
                        A super long paragraph of info.
                        A super long paragraph of info.
                        A super long paragraph of info.
                        A super long paragraph of info.
                        A super long paragraph of info.
                        A super long paragraph of info.
                    </p>

                    </div>

                </div>
            </div>
            <div className="town-page-row">
                <div className="town-padding-color">  
                        <TownBeachMaps town={details}/>

                </div>
            </div>
        </div>

    </div>     

    </>
    );
}
export default Towns;